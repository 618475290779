import { Injectable } from "@angular/core";
import { AdministrationRestService } from "@api/administration/administration-rest.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { NzMessageService } from "ng-zorro-antd/message";
import {
  EMPTY,
  catchError,
  first,
  map,
  mergeMap,
  of,
  switchMap,
  tap,
} from "rxjs";
import { EventsRestService } from "../events-rest.service";
import { EventsSource } from "../model";
import { EventsApiActions } from "./event.actions";

@Injectable()
export class EventsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly restService: EventsRestService,
    private readonly adminRestService: AdministrationRestService,
    private readonly messageService: NzMessageService,
    private readonly store: Store
  ) {}

  fetchAllEvents$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsApiActions.eventsFetch),
      switchMap((data) =>
        data.source === EventsSource.ADMIN
          ? this.adminRestService.getAllEvents()
          : of(null)
      ),
      map((events) => EventsApiActions.eventsFetchSuccess({ events }))
    )
  );

  eventFetchById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsApiActions.eventFetchById),
      mergeMap((request) =>
        this.restService.getEventById(request.id).pipe(
          first(),
          map((event) => EventsApiActions.eventFetchByIdSuccess({ event })),
          catchError(() => of(EventsApiActions.eventFetchByIdFailure({})))
        )
      )
    )
  );

  eventFetchByIdFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventsApiActions.eventFetchByIdFailure),
        map(() => EMPTY),
        tap(() =>
          this.messageService.error(
            "Nie znaleziono Rundy o podanym identyfikatorze!"
          )
        )
      ),
    {
      dispatch: false,
    }
  );

  eventPreqFetchById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EventsApiActions.eventPreqFetchById),
      tap((request) =>
        this.store.dispatch(
          EventsApiActions.eventPreqTableLoadingChange({
            eventId: request.eventId,
            value: true,
          })
        )
      ),
      mergeMap((request) =>
        this.restService.getPreqResults(request.eventId).pipe(
          first(),
          map((eventPreq) =>
            EventsApiActions.eventPreqFetchByIdSuccess({
              eventId: request.eventId,
              eventPreq,
            })
          ),
          tap((request) =>
            this.store.dispatch(
              EventsApiActions.eventPreqTableLoadingChange({
                eventId: request.eventId,
                value: false,
              })
            )
          )
        )
      )
    )
  );

  eventPreqFetchByIdFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(EventsApiActions.eventPreqFetchByIdFailure),
        map(() => EMPTY),
        tap(() =>
          this.messageService.error(
            "Wczytywanie wyników prekwalifikacji nie powiodło się!"
          )
        )
      ),
    {
      dispatch: false,
    }
  );
}

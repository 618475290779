import { createReducer, on } from "@ngrx/store";
import { EventsApiActions } from "./event.actions";
import { EventStore } from "./model";

export const emptyEvents: EventStore = {
  events: {},
  eventPreq: {},
  eventPreqTableLoading: {},
};

export const eventsReducer = createReducer(
  emptyEvents,
  on(EventsApiActions.eventsFetchSuccess, (state, effect) => {
    const result = structuredClone(state);
    effect.events.forEach((event) => {
      result.events[event.id] = event;
    });
    return result;
  }),
  on(EventsApiActions.eventFetchByIdSuccess, (state, effect) => {
    const result = structuredClone(state);
    result.events[effect.event.id] = effect.event;
    return result;
  }),
  on(EventsApiActions.eventPreqFetchByIdSuccess, (state, effect) => {
    const result = structuredClone(state);
    result.eventPreq[effect.eventId] = effect.eventPreq;
    return result;
  }),
  on(EventsApiActions.eventPreqTableLoadingChange, (state, effect) => {
    const result = structuredClone(state);
    result.eventPreqTableLoading[effect.eventId] = effect.value;
    return result;
  })
);
